import React from 'react';
import useAxios from '../../api/common';
import styles from './Health.module.css';

export function Health() {
  const url = `${process.env.REACT_APP_API_BASE_URL}/health/`;
  const [{ loading, error }, refetch] = useAxios(url);

  async function handleRefetch() {
    try {
      await refetch();
    } catch (err) {
      console.error(err);
    }
  }

  const classNames = [styles.circle];
  if (loading) {
    classNames.push(styles.loading);
  } else if (error) {
    classNames.push(styles.bad);
  } else {
    classNames.push(styles.ok);
  }

  return (
    <div className={styles.main}>
      <div className={classNames.join(' ')}>Health</div>
      <button onClick={handleRefetch}>Refetch</button>
    </div>
  );
}
