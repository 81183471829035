import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';

const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }),
  cache: false,
});

export default useAxios;
